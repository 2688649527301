import { useMutation, useQueryClient } from "@tanstack/vue-query";
import {
  orderCancel,
  orderChangePayMethodCard,
  orderChangePayMethodDebit,
  orderChangePlan,
  orderRegistCard,
  orderRegistDebit,
  orderUpdateCard,
  stopMailMagazine,
  startMailMagazine,
} from "@/query/apis/Order";
import {
  OrderChangePayMethodProp,
  OrderChangePlanProp,
  OrderRegistProp,
  OrderUpdateCardProp,
} from "@/types/Order";
import dialog from "@/vuex/dialog";
import { ReadSessionKey } from "@/query/keys";

export const useOrderCardMutation = () => {
  return useMutation((data: OrderRegistProp) => orderRegistCard(data), {
    onMutate: () => {
      dialog.addLoading("OrderRegistCard");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderRegistCard");
    },
  });
};

export const useOrderDebitMutation = () => {
  return useMutation((data: OrderRegistProp) => orderRegistDebit(data), {
    onMutate: () => {
      dialog.addLoading("OrderRegistDebit");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderRegistDebit");
    },
  });
};

export const useOrderChangePayMethodCardMutation = () => {
  return useMutation((data: OrderChangePayMethodProp) => orderChangePayMethodCard(data), {
    onMutate: () => {
      dialog.addLoading("OrderPayMethodCard");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderPayMethodCard");
    },
  });
};
export const useOrderChangePayMethodDebitMutation = () => {
  return useMutation((data: OrderChangePayMethodProp) => orderChangePayMethodDebit(data), {
    onMutate: () => {
      dialog.addLoading("OrderPayMethodDebit");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderPayMethodDebit");
    },
  });
};

export const useOrderUpdateCardMutation = () => {
  return useMutation((data: OrderUpdateCardProp) => orderUpdateCard(data), {
    onMutate: () => {
      dialog.addLoading("OrderUpdateCard");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderUpdateCard");
    },
  });
};

export const useOrderChangePlanMutation = () => {
  return useMutation((data: OrderChangePlanProp) => orderChangePlan(data), {
    onMutate: () => {
      dialog.addLoading("OrderChangePlan");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderChangePlan");
    },
  });
};

export const useOrderCancelMutation = () => {
  return useMutation(() => orderCancel(), {
    onMutate: () => {
      dialog.addLoading("OrderCancel");
    },
    onSettled: () => {
      dialog.deleteLoading("OrderCancel");
    },
  });
};

export const useStopMailMagazineMutation = () => {
  const client = useQueryClient();
  return useMutation(() => stopMailMagazine(), {
    onSuccess() {
      client.invalidateQueries([ReadSessionKey]);
    },
  });
};
export const useStartMailMagazineMutation = () => {
  const client = useQueryClient();
  return useMutation(() => startMailMagazine(), {
    onSuccess() {
      client.invalidateQueries([ReadSessionKey]);
    },
  });
};
