import { request } from "@/query/request";
import {
  OrderChangePayMethodProp,
  OrderChangePlanProp,
  OrderRegistProp,
  OrderUpdateCardProp,
} from "@/types/Order";

export const orderRegistCard = async (data: OrderRegistProp) =>
  await request({
    url: `order/regist_card`,
    method: "post",
    data,
  });

export const orderRegistDebit = async (data: OrderRegistProp) =>
  await request({
    url: `order/regist_debit`,
    method: "post",
    data,
  });

export const orderChangePayMethodCard = async (data: OrderChangePayMethodProp) =>
  await request({
    url: `order/change_pay_method_card`,
    method: "post",
    data,
  });

export const orderChangePayMethodDebit = async (data: OrderChangePayMethodProp) =>
  await request({
    url: `order/change_pay_method_debit`,
    method: "post",
    data,
  });

export const orderUpdateCard = async (data: OrderUpdateCardProp) =>
  await request({
    url: `order/update_card`,
    method: "post",
    data,
  });

export const orderChangePlan = async (data: OrderChangePlanProp) =>
  await request({
    url: `order/change_plan`,
    method: "post",
    data,
  });

export const orderCancel = async () =>
  await request({
    url: `order/cancel`,
    method: "post",
  });

export const stopMailMagazine = async () =>
  await request({
    url: `order/stop_mail_magazine`,
    method: "post",
  });
export const startMailMagazine = async () =>
  await request({
    url: `order/start_mail_magazine`,
    method: "post",
  });
